<template>
  <section class="multi-level-login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper">
          <div class="multi-level-login">
            <div class="row">
              <div class="col-12 col-lg-7 mx-auto">
                <h3 class="text-center mb-5">Create Your Account</h3>
                <stripe-checkout
                  ref="checkoutRef"
                  mode="subscription"
                  :pk="pk"
                  :line-items="lineItems"
                  :success-url="successURL"
                  :cancel-url="cancelURL"
                  @loading="(v) => (loading = v)"
                />
                <vue-good-wizard
                  :steps="steps"
                  :onNext="nextClicked"
                  :onBack="backClicked"
                  ref="wizard"
                >
                  <div slot="page1">
                    <div class="form-group">
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="First Name"
                        v-model="first_name"
                        :state="invalid.value ? !!first_name && !exists : null"
                        aria-describedby="input-1-live-feedback"
                        @focus="closeValid"
                      ></b-form-input>
                    </div>
                    <div class="form-group">
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="Last Name"
                        v-model="last_name"
                        :state="invalid.value ? !!last_name && !exists : null"
                        aria-describedby="input-1-live-feedback"
                        @focus="closeValid"
                      ></b-form-input>
                    </div>
                    <div class="form-group">
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        placeholder="Username"
                        v-model="name"
                        :state="invalid.value ? !!name && !exists : null"
                        aria-describedby="input-1-live-feedback"
                        @focus="closeValid"
                      ></b-form-input>
                    </div>
                    <div class="form-group">
                      <b-form-input
                        id="example-input-2"
                        name="example-input-2"
                        v-model="pass"
                        :state="
                          invalid.value ? !!pass && pass.length > 5 : null
                        "
                        type="password"
                        placeholder="Password"
                        aria-describedby="input-1-live-feedback"
                        @focus="closeValid"
                      ></b-form-input>
                    </div>
                    <div class="form-group">
                      <b-form-input
                        id="example-input-3"
                        name="example-input-3"
                        v-model="confirm_pass"
                        :state="
                          invalid.value
                            ? !!confirm_pass && confirmPass
                            : null
                        "
                        type="password"
                        placeholder="Confirm password"
                        aria-describedby="input-1-live-feedback"
                        @focus="closeValid"
                      ></b-form-input>
                    </div>
                    <b-form-invalid-feedback
                      id="input-5-live-feedback"
                      :class="{ active: invalid.value }"
                      v-show="invalid.value"
                    >
                      {{ invalid.text }}
                    </b-form-invalid-feedback>
                  </div>

                  <div slot="page3">
                    <div class="text-center">
                      <p class="font-weight-bold">Created sucessfully</p>
                      <i class="icon-shield text-warning icon-lg"></i>
                      <h4 class="font-weight-bold">Welcome {{ name }}</h4>
                      <p class="mb-0 text-muted">
                        You’ve successfully created an account for this user.
                      </p>
                    </div>
                  </div>
                </vue-good-wizard>
                <div class="text-center mt-4 font-weight-light">
                    Do you have an account?
                    <router-link to="/login" class="text-primary"
                      >Login</router-link
                    >
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GoodWizard } from "vue-good-wizard";
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  name: "register",
  components: {
    "vue-good-wizard": GoodWizard,
    StripeCheckout,
  },
  data() {
    return {
      firstStep: false,
      steps: [
        {
          label: "Register",
          slot: "page1",
        },
        // {
        //   label: "Email Verification",
        //   slot: "page2",
        // },
        {
          label: "Finish",
          slot: "page3",
        },
      ],
      first_name: null,
      last_name: null,
      name: null,
      pass: null,
      confirm_pass: null,
      allowed: false,
      exists: false,
      invalid: {
        value: false,
        text: "",
      },
      pk: null,
      lineItems: [
        {
          price: "price_1If7l5GuhdeOOZ9kWTpNLo5E", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: null,
      cancelURL: null,
    };
  },
  created() {
    this.pk = process.env.VUE_APP_PK;
    this.successURL = window.location.URL;
    this.cancelURL = window.location.URL;
  },
  mounted() {
    this.error();
  },
  computed: {
    confirmPass() {
      if (this.pass && this.confirm_pass) {
        return this.pass.trim() === this.confirm_pass.trim();
      } else return false;
    },
  },
  methods: {
    nextClicked(currentPage) {
      switch (currentPage) {
        case 0: {
          if (!this.firstStep) {
            this.invalid.value = true;

            if (this.pass.length < 6) {
              this.invalid.text = "Minimum password length 6";
              return 
            } else if (!this.confirmPass) {
              this.invalid.text = "Password mismatch";
              return
            } else if (this.name && this.confirmPass) {
              this.invalid.value = false;
              this.register();
            }
          }
          break;
        }
      }
      return this.allowed;
    },
    error(e) {
      console.log(e, "error");
    },
    backClicked() {
      return true;
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    async register() {
      this.invalid.value = true;
      this.firstStep = true;
      await this.$axios
        .post("/register", {
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.name,
          password: this.pass,
        })
        .then((res) => {
          this.$store.commit("updateToken", {
            token: res.data.access_token,
            refresh: res.data.refresh_token,
          });

          this.$store.commit("updateAdmin", res.data.admin);
          localStorage.setItem("username", this.name);

          this.$axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
          this.allowed = true;
          // console.log(this.$refs.wizard)
          this.$refs.wizard.goNext();

          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 2000);
        })
        .catch((err) => {
          const value = JSON.parse(err.request.response).error
          if(value.includes('exists')) this.exists = true
          this.invalid.text = value;
          // this.backClicked()
          this.firstStep = false;
        });
    },
    closeValid() {
      if(this.exists) this.exists = false;
      this.invalid.value = false;
      this.invalid.text = "";
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .wizard__steps {
  width: 300px;
  margin: 0 auto;
}
</style>
